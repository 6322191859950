<template>
  <div class="p-2">
    <div class="card">
      <form class="app-search">
        <div class="form-group w-50 mb-2">
          <label for="per-page">Nombre par page:</label>
          <input
            type="number"
            id="per-page"
            class="form-control"
            step="5"
            min="10"
            max="100"
            v-model="perPage"
          />
        </div>
        <div class="app-search-box">
          <div class="input-group">
            <input
              type="search"
              v-model="search"
              class="form-control"
              placeholder="Rechercher par titre, montants, enseignant, mois annee..."
            />
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>Cours</th>
            <th>Mois</th>
            <th>Gain</th>
            <th>Total</th>
            <th>Enseignant</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in paginate" :key="e.id">
            <td>
              {{
                e.course.title.length > 40
                  ? e.course.title.substring(0, 40) + "..."
                  : e.course.title
              }}
            </td>
            <td>{{ e.month }}/{{ e.year }}</td>
            <td>{{ e.earnedAmount }} XAF</td>
            <td>{{ e.totalAmount }} XAF</td>
            <td>
              {{ e.teacher.firstName + " " + e.teacher.lastName }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="perPage < earnings.length"
        class="d-flex justify-content-around border mt-1"
      >
        <button
          :disabled="page === 1"
          class="btn btn-sm btn-info"
          @click="prev"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <p>page {{ page }}/{{ totalPages }}</p>
        <button
          :disabled="page == totalPages"
          class="btn btn-sm btn-info"
          @click="next"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      earnings: [],
      perPage: 10,
      page: 1,
      search: "",
    };
  },
  methods: {
    async loadEarnings() {
      this.$store.dispatch("loadEarnings").then((earnings) => {
        this.earnings = earnings;
      });
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.earnings.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      return this.searchResult.slice(start, start + this.perPage);
    },
    searchResult() {
      if (!this.search) return this.earnings;
      return this.earnings.filter(
        (s) =>
          s.course.title.toLowerCase().includes(this.search) ||
          s.teacher.firstName.toLowerCase().includes(this.search) ||
          s.teacher.lastName.toLowerCase().includes(this.search) ||
          s.month.toLowerCase().includes(this.search) ||
          s.year == this.search ||
          s.earnedAmount == this.search ||
          s.totalAmount == this.search
      );
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadEarnings();
    });
  },
};
</script>
<style lang=""></style>
